import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
// import { Flip } from "gsap/Flip";
// import { ScrollTrigger } from "gsap/ScrollTrigger";
// import { Observer } from "gsap/Observer";
// import { ScrollToPlugin } from "gsap/ScrollToPlugin";
// import { Draggable } from "gsap/Draggable";
// import { MotionPathPlugin } from "gsap/MotionPathPlugin";
// import { CustomEase } from "gsap/CustomEase";
// import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";
// import { ScrollSmoother } from "gsap/ScrollSmoother";
// import { GSDevTools } from "gsap/GSDevTools";
// import { InertiaPlugin } from "gsap/InertiaPlugin";
// import { MorphSVGPlugin } from "gsap/MorphSVGPlugin";
// import { MotionPathHelper } from "gsap/MotionPathHelper";
// import { SplitText } from "gsap/SplitText";
// import { CustomBounce } from "gsap/CustomBounce";
// import { CustomWiggle } from "gsap/CustomWiggle";
//------------------------------------------------------//
// Setup 🧦 GSAP and register 📜 ScrollTrigger
// Register more plugins like so: gsap.registerPlugin(ScrollTrigger, splitText);
// Go to https://greensock.com/docs/v3/Installation?checked=core,scrollTrigger,splitText#installer
//------------------------------------------------------//
// gsap.registerPlugin(Flip, ScrollTrigger, Observer, ScrollToPlugin, Draggable, MotionPathPlugin, CustomEase, DrawSVGPlugin, ScrollSmoother, GSDevTools, InertiaPlugin, MorphSVGPlugin, MotionPathHelper, SplitText, CustomBounce, CustomWiggle);
gsap.registerPlugin(ScrollTrigger);
export default () => {
  ScrollTrigger.defaults({
    toggleActions: "restart pause resume pause",
    markers:
      location.hostname === "localhost" || location.hostname === "127.0.0.1"
        ? true
        : false,
  });

  // END Setup 🧦 GSAP -------------------------------------//

  //--------------------------------//
  // Countdown
  //--------------------------------//
  document.querySelectorAll(".countdown-date").forEach((container) => {
    const animation = {
      target: container,
      get period() {
        const dateFuture = new Date(container.dataset.date);
        const dateNow = new Date();
        let seconds = Math.floor((dateFuture - dateNow) / 1000);
        let minutes = Math.floor(seconds / 60);
        let hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        hours = hours - days * 24;
        minutes = minutes - days * 24 * 60 - hours * 60;
        seconds =
          seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;

        return {
          days: days,
          hours: hours,
          minutes: minutes,
          seconds: seconds,
        };
      },
      element: function (parent, type, className, html) {
        const element = document.createElement(type);
        element.className = className;
        if (typeof html !== "undefined") element.innerHTML = html;
        parent.appendChild(element);
        return element;
      },
      animate: function () {
        const controls = animation.element(animation.target, "div", "controls");
        const days = animation.element(controls, "div", "control days");
        const hours = animation.element(controls, "div", "control hours");
        const minutes = animation.element(controls, "div", "control minutes");
        const seconds = animation.element(controls, "div", "control seconds");
        animation.controls = {
          controls: controls,
          days: days,
          hours: hours,
          minutes: minutes,
          seconds: seconds,
        };
        animation.render();
        return gsap.from([days, hours, minutes, seconds], {
          y: 100,
          opacity: 0,
          stagger: 0.25,
        });
      },
      layout: function (property) {
        const period = animation.period;
        if (String(period[property]).length <= 1)
          period[property] = `0${period[property]}`; // Add zero prefix
        return `<span class="number">${period[property]}</span> <span>${property}</span>`;
      },
      render: function () {
        animation.controls.seconds.innerHTML = animation.layout("seconds");
        animation.controls.minutes.innerHTML = animation.layout("minutes");
        animation.controls.hours.innerHTML = animation.layout("hours");
        animation.controls.days.innerHTML = animation.layout("days");
        requestAnimationFrame(animation.render);
      },
    };
    animation.animate();
  });

  // END Countdown --------------//
};
